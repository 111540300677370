<template>
	<modal ref="modal" titulo="Editar servicio de paquetería" tamano="modal-md" adicional="Guardar" @adicional="handleCreate"
		no-aceptar>
		<ValidationObserver ref="validacion" class="row justify-content-center">
			<ValidationProvider v-slot="{ errors }" rules="required|max:60" name="nombre" class="col-10 mb-3">
				<p class="f-12 pl-2">Nombre</p>
				<el-input v-model="nombre" placeholder="Nombre del servicio" size="small" />
				<span class="text-danger f-10">{{ errors[0] }}</span>
			</ValidationProvider>
			<ValidationProvider v-slot="{ errors }" rules="required|max:120" name="dirección" class="col-10 mb-3">
				<p class="f-12 pl-2">Token</p>
				<el-input v-model="token_api" show-password placeholder="Número" size="small" />
				<span class="text-danger f-10">{{ errors[0] }}</span>
			</ValidationProvider>
			<ValidationProvider v-slot="{ errors }" rules="required|max:200" name="descripcion" class="col-10 mb-3">
				<p class="f-12 pl-2">Descripción</p>
				<el-input v-model="descripcion" type="textarea" :autosize="{ minRows: 2, maxRows: 4 }"
					placeholder="Añade una descripción" />
				<span class="text-danger f-10">{{ errors[0] }}</span>
			</ValidationProvider>
		</ValidationObserver>
	</modal>
</template>

<script>
import Servicio from "~/services/serviciosPaqueteria";
export default {
	props: {
		data: {
			type: Object,
			default: () => {
				return {}
			},
		}
	},
	data() {
		return {
			idServicio: parseInt(this.$route.params.id),
			servicio: {},
			nombre: '',
			token_api: '',
			descripcion: ''
		}
	},
	computed: {
		selectCedis() {
			return this.cedis_calculo.filter(o => o.id != 0)
		}
	},
	methods: {
		toggle() {
			this.nombre = this.data.nombre
			this.token_api = this.data.token_api
			this.descripcion = this.data.descripcion
			this.$refs.modal.toggle()
		},
		async handleCreate() {
			try {
				const valid = await this.$refs.validacion.validate()
				if (!valid) return;
				const payload = {
					nombre: this.nombre,
					descripcion: this.descripcion,
					token: this.token_api,
					estado: this.data.estado
				}
				const { data } = await Servicio.editServicio(this.idServicio, payload)
				this.notificacion('', data.mensaje, "success")
				this.$emit('update', payload)
				this.$refs.modal.toggle()
			} catch (error) {
				return this.error_catch(error)
			}
		}
	}
}
</script>

<style lang="css" scoped></style>
